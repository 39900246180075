.faq-container {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h1 {
    text-align: center;
    color: #333;
}

.accordion {
    margin-top: 20px;
}

.accordion-item {
    border-bottom: 1px solid #ddd;
}

.accordion-question {
    width: 100%;
    text-align: left;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    background-color: #f1f1f1;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-question:hover {
    background-color: #ddd;
}

.accordion-question.active {
    background-color: #ddd;
}

.accordion-icon {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.accordion-answer {
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
}

.accordion-answer p {
    margin: 10px 0;
}

.accordion-answer ul {
    margin: 10px 0;
    padding-left: 20px;
}