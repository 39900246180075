*{
  margin: 0px;
  padding: 0px;
}
body{
  overflow-x: hidden;
}
.homeContainer {
  width: 100%;
  background: url(../public/images/homeBg.png);
  height: 722px;
  flex-shrink: 0;
}
.brandLogoCenter{
  height: 100vh;
  background:#181C28;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav {
  padding: 46px 150px 0px 150px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.socialIcons {
  margin: auto 0px;
}

.socialIcons img:nth-child(1) {
  height: 24px;
  width: 13px;
}
.socialIcons img:nth-child(1):hover {
  filter: invert(42%) sepia(65%) saturate(4266%) hue-rotate(206deg) brightness(94%) contrast(99%);
}
.socialIcons img:nth-child(2):hover {
  filter: invert(69%) sepia(57%) saturate(4949%) hue-rotate(176deg) brightness(92%) contrast(106%);
}
.socialIcons img:nth-child(3):hover {
  filter: invert(42%) sepia(84%) saturate(7206%) hue-rotate(200deg) brightness(88%) contrast(92%);
}
.socialIcons img:nth-child(2) {
  height: 24px;
  width: 28px;
  margin: 0px 60px;
}
.socialIcons img:nth-child(2) {
  height: 24px;
  width: 25px;
}

/* homeContent start */
.homeContent {
  padding: 0px 150px;
}
.homeContent h1 {
  color: var(--text-white, #f8f8f8);
  font-family: Inria Serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.homeContent p {
  color: var(--text-white, #f8f8f8);
  font-family: Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.p1 {
  margin-top: 35px;
  margin-bottom: 20px;
}
.p2 {
  margin-bottom: 35px;
}
.homeContent button {
  color: #fff;
  font-family: Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 387px;
  height: 66.861px;
  background-color: transparent;
  border: 2px solid #9b70f8;
  border-radius: 4px;
}

 
.homeContent button:hover{
  transition: ease 2s all;
  background: #9b70f8;
}

.headers{
  padding: 60px 0px;
  position: relative;
  animation: slide 1s ease-in;
  width: 50%;
}
@keyframes slide {
  0%{
    left: -800px;
  } 
  100%{
    left: 0px;
  }
}
.bannerImg img{
  margin-top: -50px;
  position: relative;
  right: -150px;
  width: 100%;
  animation: imgSlide 1s ease-in;
}
@keyframes imgSlide {
  0%{
    right: -800px;
  } 
  100%{
    right: -150px;
  }
}
/* homeContent end */

/* bookGallery start */

.Slide{
  display: flex;
  overflow: auto;
}
.Slide::-webkit-scrollbar{
  display: none;
}
.Slide img{
  margin: 30px 20px;
}
.Slide img:first-child{
  margin-left: 0px;
}

.bookGallery h1 {
  text-align: center;
  color: #232323;
  font-family: Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px; /* 137.5% */
  text-transform: uppercase;
  margin-top: 30px;
}
/* bookGallery end */

/* detail start */
.detail {
  padding: 60px 150px;
}
.detail h1 {
  margin: auto 0px;
  color: #232323;

  font-family: Helvetica;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px; /* 137.5% */
  text-transform: uppercase;
  width: 556px;
}
.firstH1 {
  margin-left: 80px !important;
  text-align: right;
}
.detail img {
  border-radius: 12px;
}
.firstH2 {
  margin-right: 80px !important;
}
/* detail end */

/* footer start */
.footer {
  background: #181c28;
  height: 660.788px;
  padding: 0px 150px;
}
.footer h1 {
  color: var(--text-white, #f8f8f8);
  font-family: Archivo;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.footer p {
  color: var(--text-white, #f8f8f8);
  font-family: Archivo;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 126.8%;
  margin-bottom: 50px;
}
.footerContent img {
  width: 297px;
  height: 88px;
}
.footerContent img:last-child{
  margin-top: 30px;
}
.playButton:hover{
  scale: 1.1;
  margin-left: 25px;
  transition: ease-in .5s;
}

/* footer end */

/* copyRight start */
.copyRight{
  padding: 60px 150px;
  background: #FFFCF9;
}
.copyRight p{
  text-align: center;
  color: var(--body-black, #404040);
font-family: Archivo;
font-size: 28px !important;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 0px;
}
/* copyRight start */

/* banner css start */

/* img {
  max-width: 150px;
  height: 5rem;
  padding: 0 15px;
  object-fit: cover;
}

img:last-of-type {
  padding-left: 0;
} */

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 240px;
}
.image{
  margin: 30px 20px;
}
.wrapper {
  position: absolute;
  display: flex;
}

.leftSlide {
  display: flex;
  animation: swipeleft var(--speed) linear infinite backwards;
}

@keyframes swipeleft {
  0% {
    transform: translate(0%);
  }

  100% {
    transform: translate(-100%);
  }
}
.rightSlide {
  display: flex;
  animation: swiperight var(--speed) linear infinite forwards;
}

@keyframes swiperight {
  0% {
    transform: translate(-100%);
  }

  100% {
    transform: translate(0%);
  }
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}

.footer-links a {
  color: #f8f8f8;
  text-decoration: none;
  font-family: Helvetica;
  font-size: 14px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #9b70f8;
}

/* banner css end */

@media screen and (max-width: 1024px) {
  .nav {
    padding: 30px 50px;
  }

  .homeContent {
    padding: 0px 50px;
    flex-direction: column;
  }

  .headers {
    width: 100%;
    padding: 30px 0px;
  }

  .homeContent h1 {
    font-size: 48px;
  }

  .homeContent button {
    width: 100%;
    max-width: 387px;
  }

  .bannerImg img {
    right: 0;
    margin-top: 20px;
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .detail {
    padding: 40px 50px;
  }

  .detail h1 {
    font-size: 32px;
    width: 100%;
    line-height: 45px;
  }

  .firstH1, .firstH2 {
    margin: 30px 0 !important;
    text-align: center;
  }

  .footer {
    padding: 40px 50px;
    height: auto;
    flex-direction: column;
  }

  .footer h1 {
    font-size: 36px;
  }

  .footer p {
    font-size: 28px;
  }

  .footer img {
    margin-top: 40px;
    max-width: 100%;
  }
}

/* Mobile Responsive Design (up to 767px) */
@media screen and (max-width: 767px) {
  .nav {
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }

  .brand {
    margin-bottom: 20px;
  }

  .socialIcons img:nth-child(2) {
    margin: 0px 30px;
  }

  .homeContent {
    padding: 0px 20px;
  }

  .homeContent h1 {
    font-size: 36px;
  }

  .homeContent p {
    font-size: 14px;
  }

  .p1 {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .bannerImg img {
    width: 100%;
  }

  .bookGallery h1 {
    font-size: 28px;
    line-height: 40px;
    padding: 0 20px;
  }

  .detail {
    padding: 30px 20px;
    flex-direction: column;
  }

  .detail img {
    width: 100%;
    margin-bottom: 20px;
  }

  .detail h1 {
    font-size: 24px;
    line-height: 35px;
  }

  .footer {
    padding: 30px 20px;
  }

  .footer h1 {
    font-size: 32px;
  }

  .footer p {
    font-size: 24px;
  }

  .footerContent img {
    width: 100%;
    height: auto;
    max-width: 297px;
  }

  .copyRight {
    padding: 30px 20px;
  }

  .copyRight p {
    font-size: 20px !important;
  }

  /* Adjust banner for mobile */
  .inner {
    height: 180px;
  }

  .image {
    margin: 20px 10px;
  }

  .image img {
    max-width: 120px;
  }
}

/* Fix animation for mobile devices */
@media screen and (max-width: 767px) {
  @keyframes slide {
    0% {
      left: -100%;
    }
    100% {
      left: 0;
    }
  }

  @keyframes imgSlide {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  .footer-links {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  .footerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-links a {
    font-size: 12px;
  }
  .footerImg{
    display: none;
  }
}

/* Handle very small screens */
@media screen and (max-width: 320px) {
  .homeContent h1 {
    font-size: 28px;
  }

  .bookGallery h1 {
    font-size: 24px;
  }

  .footer h1 {
    font-size: 28px;
  }

  .footer p {
    font-size: 20px;
  }

  .footerContent{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footerImg{
    display: none;
  }
}