.sharing-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.sharing-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2c3e50;
    text-align: center;
}

.sharing-intro {
    font-size: 1rem;
    margin-bottom: 15px;
}

.section-title {
    font-size: 1.8rem;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #34495e;
}

.subsection-title {
    font-size: 1.4rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #2c3e50;
}

.section-text,
.subsection-text {
    font-size: 1rem;
    margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .sharing-container {
        padding: 15px;
        margin: 10px;
    }

    .sharing-title {
        font-size: 2rem;
    }

    .section-title {
        font-size: 1.6rem;
    }

    .subsection-title {
        font-size: 1.2rem;
    }

    .section-text,
    .subsection-text {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .sharing-title {
        font-size: 1.8rem;
    }

    .section-title {
        font-size: 1.4rem;
    }

    .subsection-title {
        font-size: 1.1rem;
    }

    .section-text,
    .subsection-text {
        font-size: 0.85rem;
    }
}