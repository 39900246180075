
  
  .privacy-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    color: #333;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .privacy-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #2c3e50;
    text-align: center;
  }
  
  .privacy-intro {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .section-title {
    font-size: 1.8rem;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #34495e;
  }
  
  .section-text {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .contact-link {
    color: #3498db;
    text-decoration: none;
  }
  
  .contact-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .privacy-container {
      padding: 15px;
      margin: 10px;
    }
  
    .privacy-title {
      font-size: 2rem;
    }
  
    .section-title {
      font-size: 1.6rem;
    }
  
    .section-text {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .privacy-title {
      font-size: 1.8rem;
    }
  
    .section-title {
      font-size: 1.4rem;
    }
  
    .section-text {
      font-size: 0.85rem;
    }
  }